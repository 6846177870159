.site-navbar {
    display: flex;
    justify-content: center;
  }
  
  .nav-items-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 15px;
    width: 100%;
  }
  
  .brand-logo {
    display: flex;
    align-items: center;
    margin-right: 100px !important;
  }
  
  .search-bar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .search-form {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
  
  .search-input {
    margin: 0;
    width: 100%;
    height: 60px;
    color: white;
    border-radius: 50px;
    background-color: transparent;
    padding: 15px 30px!important;
    border: none!important;
    transition: all .4s;
    border: 1px solid white !important;
}
  .search-input::placeholder {
    color: #888 !important; /* Change this color value to your desired color */
    opacity: 1 !important; /* Required for some browsers to show the color */
  }
  
  /* For Internet Explorer 10-11 */
  .search-input:-ms-input-placeholder {
    color: #888 !important; /* Change this color value to your desired color */
  }
  
  /* For Edge */
  .search-input::-ms-input-placeholder {
    color: #888 !important; /* Change this color value to your desired color */
  }
  
  .search-input:focus {
    outline: none !important;
    border-color: #0fd1a6 !important;
  }
  
  .search-button {
    background: none;
    border: none;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  
  .search-button i {
    color: #333;
    font-size: 18px;
  }
  
  .deep-pink {
    /* background-color: #0fd1a6 !important; */
  }

  /*1.2 components css*/
.zuzu-btn-header {
    font-size: 16px !important;
    line-height: 24px;
    border-radius: 3px;
    padding: 15px 36px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    width: fit-content;
    position: relative;
    z-index: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: center;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    color: #181a21;
    background-color: #0fd1a6;
  }

  .zuzu-btn-header:hover {
    color: #181a21;
    background-color: white;
  }

  .mobile_bg{
    background-color: #181a21 !important;
    color: white !important;
  }
  

  .light-version-logo{
    max-width: 160px !important;
  }
  
  #header-component .menu-block-wrapper {
    display: none !important;
  }
  
  @media (max-width: 767px) {
    #header-component .menu-block-wrapper {
      display: block !important;
    }
  }